import React from "react";
import "./App.css";

function Root() {
  return (
    <main>
      <h2>Hi, I'm Rubens</h2>
    </main>
  );
}

export default Root;
